import React from 'react'
import { Link as RouteLink } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import get from 'lodash/get'

import { media } from '../themes/index'

import { MainTheme } from '../themes'

import IconInstagram from '../components/icons/IconInstagram'
import IconFacebook from '../components/icons/IconFacebook'

const HeaderIconInstagram = styled(IconInstagram)`
  width: 18px;
  vertical-align: sub;
  path {
    fill: ${props => props.theme.header.link_color};
  }
`
const HeaderIconFacebook = styled(IconFacebook)`
  width: 18px;
  vertical-align: sub;
  path {
    fill: ${props => props.theme.header.link_color};
  }
`

const Container = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 5px 0;
  z-index: 9999;
  background: ${props => props.theme.header.bg_color};
`

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 8px;
  line-height: 100%;
  margin: 0 10px;
  font-size: 2rem;
  text-transform: lowercase;
  text-align: left;

  ${media.tablet` 
    letter-spacing: 6px;
  `}
`

const MenuItem = styled.li`
  display: inline-block;
  padding: 0 10px;
  margin: 0;

  ${media.tablet`
    line-height: initial;

    ${HeaderIconFacebook} {
      width: 48px;
      margin: 20px 0 0;
    }
    ${HeaderIconInstagram} {
      width: 48px;
      margin: 20px 0 0;
    }
    

  `}
`

const MobileMenu = styled.button`
  display: none;

  font-size: 2rem;
  line-height: 90%;
  font-weight: 300;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  padding-top: 8px;

  color: ${props => props.theme.header.link_color};

  ${media.tablet`
    display: block;
  `}
`

const MobileMenuClose = styled(MobileMenu)``

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${media.tablet`
      display: ${({ showMenu }) => (showMenu ? 'flex' : 'none')};
      top: 0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255,255,255,0.95);

      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-size: 2.8rem;

      ${MobileMenu} {
        font-size: 5rem;
        position: fixed;
        bottom: 0;
        right: 0px;
        height: auto;
      }

      ${MenuItem}, ${HeaderIconFacebook} {
        a {
          color: ${props => props.theme.menu.color};
        }
      }

      ${HeaderIconFacebook}, ${HeaderIconInstagram} {
        path {
          fill: ${props => props.theme.menu.color};
        }
      }

  `}
`

// this is only active when the location pathname is exactly
// the same as the href.

const Link = styled(RouteLink)`
  color: ${props => props.theme.header.link_color};
  text-decoration: none;
  vertical-align: sub;

  &[aria-current] {
    color: ${props => props.theme.header.active_color};
  }
`

const ExternalLink = styled.a`
  vertical-align: sub;
`

class Header extends React.Component {
  state = {
    showMenu: false,
  }

  onToggleMenu = () => {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const { siteTitle, pageResources } = this.props

    let theme = MainTheme

    if (get(pageResources, 'page.jsonName') === 'index') {
      theme = Object.assign(
        {},

        MainTheme,
        {
          header: {
            color: '#FFF',
            link_color: '#FFF',
            bg_color: 'transparent',
            active_color: '#FFF',
          },
        }
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Title>
            <Link to="/">{siteTitle}</Link>
          </Title>
          <MobileMenu type="button" onClick={this.onToggleMenu}>
            =
          </MobileMenu>
          <Menu showMenu={this.state.showMenu}>
            <MenuItem>
              <Link to="/news">News</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/bio">Biografia</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/paintings">Opere</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/scritti">Scritti</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/contatti">Contatti</Link>
            </MenuItem>

            <MenuItem>
              <ExternalLink href="https://www.facebook.com/simonefaziopittura">
                <HeaderIconFacebook />
              </ExternalLink>
            </MenuItem>
            <MenuItem>
              <ExternalLink href="https://www.instagram.com/simonefazio9">
                <HeaderIconInstagram />
              </ExternalLink>
            </MenuItem>
            <MobileMenu type="button" onClick={this.onToggleMenu}>
              ×
            </MobileMenu>
          </Menu>
        </Container>
      </ThemeProvider>
    )
  }
}

export default Header
