import React from 'react'

const IconFacebook = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="102.416 287.475 18.584 18.584"
      width="100%"
      height="100%"
    >
      <path
        d=" M 119.974 287.475 L 103.442 287.475 C 102.876 287.475 102.416 287.934 102.416 288.501 L 102.416 305.033 C 102.416 305.599 102.876 306.058 103.442 306.058 L 112.343 306.058 L 112.343 298.872 L 109.93 298.872 L 109.93 296.059 L 112.343 296.059 L 112.343 293.99 C 112.343 291.59 113.809 290.283 115.951 290.283 C 116.976 290.283 117.858 290.359 118.114 290.392 L 118.114 292.9 L 116.638 292.9 C 115.473 292.9 115.247 293.454 115.247 294.266 L 115.247 296.059 L 118.032 296.059 L 117.669 298.872 L 115.247 298.872 L 115.247 306.058 L 119.974 306.058 C 120.541 306.058 121 305.599 121 305.033 L 121 288.501 C 121 287.934 120.541 287.475 119.974 287.475 L 119.974 287.475 Z "
        fillRule="evenodd"
        fill="rgb(255,255,255)"
      />
    </svg>
  )
}

export default IconFacebook
