import { css } from 'styled-components'

export const sizes = {
  large: 1170,
  desktop: 992,
  tablet: 768,
  phone: 520,
}

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const MainTheme = {
  header: {
    color: '#000',
    link_color: '#666',
    bg_color: '#fff',
    active_color: '#000',
  },
  menu: {
    color: '#000',
  },
  page: {
    body: {
      primary_text_color: '#000',
      secondary_text_color: '#999',
    },
  },
  // Styled System
  fontSizes: ['2rem', '5rem'],
  breakpoints: ['300px', '768px', '1280px'],
}
